.login_Box {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 22px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding:1.5em 2em;
  width: 25%;
  height: fit-content;
  margin-left: auto;
  margin-top: -1%;
}

.login_Box h3 {
  font: normal normal 600 36px/69px Poppins;
  letter-spacing: 0px;
  color: #191a1c;
}
.login_Box p {
  font: normal normal 600 13px Poppins;
  letter-spacing: 0px;
  color: #191a1c;
}