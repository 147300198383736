@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.error {
  color: orangered;
  font-size: 0.75rem;
  margin-top: 0.2rem;
  margin-left: 0.25rem;
  font-weight: 600;
}

.MuiAutocomplete-inputFocused {
  border-color: transparent !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: transparent !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: transparent !important;
}

.custom-row {
  background: #F6F6F6;
  border-radius: 20px;
  border: none;
  margin-top: 8px;
  /* height: 60px; */
}